// store/auth.ts
// https://dev.to/rafaelmagalhaes/authentication-in-nuxt-3-375o

import { defineStore } from 'pinia';
import type { IUserLoginPayload } from '~/types/index';

export const useAuthStore = defineStore('auth-store', {
  // https://pinia.vuejs.org/cookbook/composables.html#Option-Stores
  state: () => ({
    authenticated: false,
    role: '',
    clientId: '',
    userId: ''
  }),

  actions: {
    async loginUser({ email, password }: IUserLoginPayload) {

      // useFetch from nuxt 3
      try {
        const user = await useFetchAuth('/api/v1/back/user/login',
          {
            method: 'POST',
            body: {
              email,
              password,
            }
          });

          // console.log('user -> ', user);


          // 'failed' is returned from the server on failed login. No errors is provided
          if (user !== 'failed') {
            // https://nuxt.com/docs/api/composables/use-cookie
            const _vh_user_token = useCookie('_vh_user_token'); // useCookie new hook in nuxt 3
            const _vh_legged_in_email = useCookie('_vh_legged_in_email'); // useCookie new hook in nuxt 3
            const _vh_logged_in_user_id = useCookie('_vh_logged_in_user_id'); // useCookie new hook in nuxt 3
            const _vh_logged_in_client_id = useCookie('_vh_logged_in_client_id'); // useCookie new hook in nuxt 3

            _vh_user_token.value = (user as { token: string }).token; // set token to cookie
            _vh_legged_in_email.value = email; // set token to cookie
            _vh_logged_in_user_id.value = (user as { userId: string }).userId; // set token to cookie
            _vh_logged_in_client_id.value = (user as { clientId: string }).clientId; // set token to cookie
            this.authenticated = true; // set authenticated state value to true
            this.role = (user as { role: string }).role; // set role state value to true
            this.clientId = (user as { clientId: string }).clientId; // set client id this user belongs to
            this.userId = (user as { userId: string }).userId; // set client id this user belongs to
            return useToast().success(`You are now logged in as ${(user as { role: string }).role}.`);
          } 
          else{
            return useToast().error('Login failed. Please try again.');
          }
      } catch (e) {
        const error = e as Error;
        useToast().error(error);
      }

    },
    logOutUser() {
      const _vh_user_token = useCookie('_vh_user_token'); // useCookie new hook in nuxt 3
      const _vh_legged_in_email = useCookie('_vh_legged_in_email'); // useCookie new hook in nuxt 3
      const _vh_logged_in_user_id = useCookie('_vh_logged_in_user_id'); // useCookie new hook in nuxt 3
      const _vh_logged_in_client_id = useCookie('_vh_logged_in_client_id'); // useCookie new hook in nuxt 3
      _vh_user_token.value = null; // clear the token cookie
      _vh_legged_in_email.value = null; // clear the username cookie
      _vh_logged_in_user_id.value = null; // clear the username cookie
      _vh_logged_in_client_id.value = null; // clear the username

      this.authenticated = false; // set authenticated state value to false
      this.role = ''; // set role state value to false
      this.clientId = ''; // set client id this user belongs to
      this.userId = ''; // set client id this user belongs to
    },
  },
});